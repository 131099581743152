




































































































import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { Observer } from 'mobx-vue'
import { Component, Vue, Provide, Watch, Inject, Prop } from 'vue-property-decorator'
import { ClaimerPoolsViewModel } from '../viewmodels/claimer-pools-viewmodel'

@Observer
@Component({
  components: {
    VestingScheduleForm: () => import('../components/vesting-schedule-form.vue')
  }
})
export default class AddWinnerdialog extends Vue {
  @Inject() vm!: ClaimerPoolsViewModel
  @Prop({ default: false }) state
  loading = false
  tokenAddress = ''
  name = ''
  avatarUrl = ''
  coverUrl = ''
  description = ''

  resetDefault() {
    this.tokenAddress = ''
    this.name = ''
    this.avatarUrl = ''
    this.coverUrl = ''
    this.description = ''
    this.loading = false
  }

  async getData() {
    if (!(this.$refs.form! as any).validate() || !(this.$refs.vestingScheduleForm! as any).validate()) return

    const res = (this.$refs.vestingScheduleForm! as any).getData()
    if (!res) return
    const configs = JSON.parse(JSON.stringify(res))
    try {
      this.vm.changeAddClaimDialogLoading(true)
      const payload = {
        tokenAddress: this.tokenAddress,
        name: this.name,
        avatarUrl: this.avatarUrl,
        coverUrl: this.coverUrl,
        description: this.description
      }
      await this.vm.addClaim(payload, configs)
      this.vm.requestAddWinnerListDialog(false)
      snackController.success('Pool added successfully')
    } catch (e) {
      snackController.error(e.message || e.msg)
    } finally {
      this.vm.changeAddClaimDialogLoading(false)
    }
  }

  @Watch('state', { immediate: true }) resetData(value) {
    if (!value) return
    this.resetDefault()
    if (this.$refs.form) {
      ;(this.$refs.form as any).resetValidation()
      if (this.$refs.vestingScheduleForm) {
        ;(this.$refs.vestingScheduleForm as any).reset()
      }
    }
  }
}
